import React from "react"
import Layout from "../components/layout"
import { Link, graphql } from 'gatsby'
import SEO from "../components/seo"
import styled from "@emotion/styled"

/* Movies Content Container */
const MoviesContentContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
          justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 10rem;
`

/* Movies Header */
const MoviesHeaderContainer = styled.div`
  margin-bottom: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
          align-items: center;
  width: 100%;  
`

const MoviesTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
`

const MoviesLine = styled.div`
  background-color: #7798ab;
  width: 1rem;
  margin: 0 auto;
  height: 0.1rem;
  margin-top: 0.5rem;
`

/* Movies Archive Looks */
/* Make sure all cover images are the same cropped dimentions! */
const MoviesTitleContainer = styled.div`
  cursor: pointer;
  width: 250px;
  margin: 0 auto;
  margin: 1rem;
  text-align: center;
  border: 2px solid black;

  @media (max-width: 425px) {
    width: 80%;
  }
`
const MoviesLink = styled(props => <Link {...props} />)`
  text-decoration: none;
  cursor: pointer;
`

const MoviesArchiveTitle = styled.h3`
  color: black;
  font-style: italic;
  font-weight: bold;
  letter-spacing: 0.05rem;
  font-size: 1.5rem;

  @media (max-width: 320px) {
    line-height: 1.25rem; 
  }
`

const BookArchiveTemplate = ({ data, location }) => {
    const posts = data.allMarkdownRemark.edges;
    return (
      <Layout location={location}>
        <SEO title="Movies" />
        <MoviesContentContainer>
          <MoviesHeaderContainer>
            <MoviesTitle>Movies</MoviesTitle>
            <MoviesLine>&nbsp;</MoviesLine>
          </MoviesHeaderContainer>
          {posts.map(({ node }) => {
              const title = node.frontmatter.title || node.fields.slug;
              const image = node.frontmatter.image1;
              return (
                <MoviesTitleContainer key={node.fields.slug}>
                  <MoviesLink to={node.fields.slug}>
                  <img src={image} alt={title} style={{marginBottom: 0}} />
                  <MoviesArchiveTitle>
                      {title}
                  </MoviesArchiveTitle>
                  </MoviesLink>
                </MoviesTitleContainer>
              )
          })}
        </MoviesContentContainer>
      </Layout>
    )
};

export default BookArchiveTemplate

export const pageQuery = graphql`
query MoviesListTemplateQuery {
  allMarkdownRemark(
    sort: { fields: [frontmatter___title], order: ASC }
    filter: {fileAbsolutePath: {regex: "content/movies/"}}) {
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          image1
        }
      }
    }
  }
  site {
    siteMetadata {
      title
    }
  }
}
`